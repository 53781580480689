import { gql } from "@apollo/client";

export const APP_SETTING = gql`
  query appSettingQuery($clientId: Int!, $userId: String) {
    directoryProps(clientId: $clientId) {
      primaryNetworkId
      privateNetworkId
      clientId
    }
    exportConfig(clientId: $clientId) {
      exportValue
      exportType
      clientId
      enabled
    }
    client(clientId: $clientId) {
      mode
      serviceRequestType
      enableEntityDetection
      name
    }
    networks {
      id
      name
      owner
    }
    clientDashboards(clientId: $clientId) {
      id
      isMemberOnly
      name
      networkId
      qsDashboardId
    }
    user(userId: $userId) {
      firstName
      lastName
      id
      inactive
      superadmin
      multiTenant
      tenantadmin
      clientId
      email
      userName
    }
  }
`;
