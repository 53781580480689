import axios from "axios";
import { getApiHost } from "../state/ducks/api";

let firstPatientPool;

async function getClientPatientPools(auth, clientId) {
  const apiHost = await getApiHost();
  const patientPoolResponse = await axios.get(
    `${apiHost}/api/clients/${clientId}/patient-pools`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );

  return patientPoolResponse?.data;
}

export async function createPatientInPool(
  auth,
  clientId,
  newPatientInfo,
  patientPool
) {
  const apiHost = await getApiHost();

  if (
    newPatientInfo?.homePhoneNumber?.trim() === "-\u2000\u2000\u2000-" ||
    newPatientInfo?.homePhoneNumber?.trim() === ""
  ) {
    newPatientInfo.homePhoneNumber = "";
  }
  if (
    newPatientInfo?.workPhoneNumber?.trim() === "-\u2000\u2000\u2000-" ||
    newPatientInfo?.workPhoneNumber?.trim() === ""
  ) {
    newPatientInfo.workPhoneNumber = "";
  }

  let defaultPatientPool = firstPatientPool;
  if (!patientPool && !defaultPatientPool) {
    const patientPools = await getClientPatientPools(auth, clientId);
    defaultPatientPool = patientPools[0]?.patientPoolId;
    firstPatientPool = defaultPatientPool;
  }
  const response = await axios
    .post(
      `${apiHost}/api/patient-pools/${
        patientPool || defaultPatientPool
      }/patients`,
      {
        ...newPatientInfo,
        insurance: newPatientInfo.insurance?.filter(
          (insure) =>
            Object.values(insure)?.filter((value) => value)?.length > 1
        ),
      },
      {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      }
    )
    .catch((e) => {
      if (e.response) {
        if (e.response.data.status === 409) {
          return {
            data: {
              isError: true,
              errorMessage: e.response.data.message || e.response.data.error,
            },
          };
        }
        throw new Error(e);
      }
    });
  return response.data;
}

export async function updatePatient(auth, clientId, updatedPatientInfo) {
  const apiHost = await getApiHost();
  if (
    updatedPatientInfo?.homePhoneNumber?.trim() === "-\u2000\u2000\u2000-" ||
    updatedPatientInfo?.homePhoneNumber?.trim() === ""
  ) {
    updatedPatientInfo.homePhoneNumber = "";
  }
  if (
    updatedPatientInfo?.workPhoneNumber?.trim() === "-\u2000\u2000\u2000-" ||
    updatedPatientInfo?.workPhoneNumber?.trim() === ""
  ) {
    updatedPatientInfo.workPhoneNumber = "";
  }

  let defaultPatientPool = firstPatientPool;
  if (!updatedPatientInfo?.patientPoolId && !defaultPatientPool) {
    const patientPools = await getClientPatientPools(auth, clientId);
    defaultPatientPool = patientPools[0]?.patientPoolId;
    firstPatientPool = defaultPatientPool;
  }
  const response = await axios.put(
    `${apiHost}/api/patient-pools/${
      updatedPatientInfo?.patientPoolId || defaultPatientPool
    }/patients/${updatedPatientInfo.id}`,
    {
      ...updatedPatientInfo,
      insurance: updatedPatientInfo.insurance?.filter(
        (insure) => Object.values(insure)?.filter((value) => value)?.length > 1
      ),
    },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return response.data;
}

export async function fetchPatient(auth, clientId, patientId, patientPool) {
  const apiHost = await getApiHost();
  let defaultPatientPool = firstPatientPool;
  if (!patientPool && !defaultPatientPool) {
    const patientPools = await getClientPatientPools(auth, clientId);
    defaultPatientPool = patientPools[0]?.patientPoolId;
    firstPatientPool = defaultPatientPool;
  }
  const response = await axios.get(
    `${apiHost}/api/patient-pools/${
      patientPool || defaultPatientPool
    }/patients/${patientId}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return response.data;
}
