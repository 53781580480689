import { gql } from "@apollo/client";
import { assigneeFieldsFragment } from "./fragments/assigneeFields";
import { senderProviderFieldsFragment } from "./fragments/senderProviderFields";
import { seenAtProviderFieldsFragment } from "./fragments/seenAtProviderFields";
import { recipientPracticeFieldsFragment } from "./fragments/recipientPracticeFields";
import { patientFieldsFragment } from "./fragments/patientFields";
import { documentFieldsFragment } from "./fragments/documentFields";
import { providerFieldsFragment } from "./fragments/providerFields";

export const REFERRAL_SEARCH = gql`
  query referralQuery(
    $query: String
    $filters: [SKFiltersSet]
    $page: SKPageInput
    $sortBy: String
  ) {
    referrals {
      results(query: $query, filters: $filters) {
        summary {
          total
          sortOptions {
            id
            label
          }
          appliedFilters {
            id
            identifier
            display
            label
            ... on ValueSelectedFilter {
              value
            }
            ... on DateRangeSelectedFilter {
              dateMin
              dateMax
            }
          }
          query
        }
        hits(page: $page, sortBy: $sortBy) {
          sortedBy
          page {
            total
            totalPages
            pageNumber
            from
            size
            __typename
          }
          items {
            ... on ReferralResultHit {
              id
              fields {
                id
                type
                clientId
                receiverClientId
                status
                flagged
                priority
                displayableReferralId
                composedPatientFirstName
                composedPatientLastName
                composedPatientDob
                lastActivityTime
                sentTime
                consultDate
                senderPractice
                referralRecipientPhysician
                recipientPracticeOrgName
                senderName
                specialty
                assigneeFirstName
                assigneeLastName
              }
            }
          }
        }
        facets {
          identifier
          type
          label
          display
          entries {
            value
            label
            count
            __typename
          }
          __typename
        }
      }
    }
  }
`;

export const GET_REFERRALS_FOR_KANBAN = gql`
  query referralQuery(
    $query: String
    $filters: [SKFiltersSet]
    $page: SKPageInput
    $sortBy: String
  ) {
    referrals {
      results(query: $query, filters: $filters) {
        hits(page: $page, sortBy: $sortBy) {
          sortedBy
          page {
            total
            totalPages
            pageNumber
            from
            size
            __typename
          }
          items {
            ... on ReferralResultHit {
              fields {
                id
                type
                status
                flagged
                priority
                displayableReferralId
                displayableId
                composedPatientFirstName
                composedPatientLastName
                composedPatientDob
                lastActivityTime
                clientId
                receiverClientId
                consultDate
                senderPractice
                referralRecipientPhysician
                recipientPracticeOrgName
                senderName
                specialty
                assigneeFirstName
                assigneeLastName
                startTime
                recipientPracticeProviderNetworkId
                recipientPracticeProviderNetworks
                recipientPracticeProviderReferenceUrns
                senderProviderNetworks
                recipientPracticeProviderId
                assigneeUserId
                senderProviderOrgName
                seenAtProviderOrgName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_REFERRAL = gql`
  query referralQuery(
    $clientId: Int
    $processInstanceId: String
    $shouldIncludeAssignee: Boolean! = true
    $shouldIncludePatient: Boolean! = true
    $shouldIncludeRecipientPractice: Boolean! = true
    $shouldIncludeSeenAtProvider: Boolean! = true
    $shouldIncludeSenderProvider: Boolean! = true
    $shouldIncludeDocuments: Boolean! = true
  ) {
    referral(clientId: $clientId, processInstanceId: $processInstanceId) {
      id
      processDefinitionName
      processDefinitionKey
      processDefinitionId
      assigneeId
      assignee @include(if: $shouldIncludeAssignee) {
        ...AssigneeFields
      }
      patientSnapshot @include(if: $shouldIncludePatient) {
        ...PatientFields
      }
      patient @include(if: $shouldIncludePatient) {
        ...PatientFields
      }
      recipientPractice @include(if: $shouldIncludeRecipientPractice) {
        ...RecipientPracticeFields
      }
      seenAtProvider @include(if: $shouldIncludeSeenAtProvider) {
        ...SeenAtProviderFields
      }
      senderProvider @include(if: $shouldIncludeSenderProvider) {
        ...SenderProviderFields
      }
      documents @include(if: $shouldIncludeDocuments) {
        ...DocumentFields
      }
      clientId
      startTime
      completed
      type
      displayableId
      taskId
      status
      description
      flagged
      senderProcessId
      networkId
      specialty
      priority
      recipientContactType
      recipientContactNumber
      senderPractice
      senderName
      sentTime
      senderClientId
      receiverClientId
      patientId
      sentReferralNetworkId
      referralRecipientPhysician
      senderPractice
      senderName
      oonReason
      requestedServices
      consultDate
      externalId
      visitNumber
      referralRecipientPhysicianActual
    }
  }
  ${assigneeFieldsFragment}
  ${patientFieldsFragment}
  ${recipientPracticeFieldsFragment}
  ${seenAtProviderFieldsFragment}
  ${senderProviderFieldsFragment}
  ${documentFieldsFragment}
  ${documentFieldsFragment}
  ${providerFieldsFragment}
`;
