export const getChangedProperties = (originalObject, updatedObject) => {
  return Object.keys(updatedObject).reduce((changedProperties, key) => {
    if (originalObject[key] !== updatedObject[key]) {
      changedProperties[key] = updatedObject[key];
    }
    return changedProperties;
  }, {});
};

export const getUniquePatientNamesFromServiceRequestsList = (
  serviceRequestsList
) => {
  return [
    ...new Set(
      serviceRequestsList.map((serviceRequest) => {
        return `${serviceRequest?.fields?.firstName} ${serviceRequest?.fields?.lastName}`;
      })
    ),
  ];
};

export const processServiceRequestsByPatient = (
  patientNames,
  serviceRequestList
) => {
  return patientNames.map((patientName) => {
    let filteredServiceRequests = serviceRequestList.filter(
      (serviceRequest) =>
        `${serviceRequest?.fields?.firstName} ${serviceRequest?.fields?.lastName}` ===
        patientName
    );
    return {
      isOpen: "closed",
      firstName: filteredServiceRequests[0]?.fields?.firstName,
      lastName: filteredServiceRequests[0]?.fields?.lastName,
      dob: filteredServiceRequests[0]?.fields?.dob,
      mrn: filteredServiceRequests[0]?.fields?.mrn,
      serviceRequests: filteredServiceRequests?.map(
        (serviceRequestObj) => serviceRequestObj?.fields
      ),
    };
  });
};

export const groupServiceRequestsByPatient = (serviceRequestList) => {
  const patientNames =
    getUniquePatientNamesFromServiceRequestsList(serviceRequestList);
  return processServiceRequestsByPatient(patientNames, serviceRequestList);
};

export const formatPatientDefaultValue = (data) => {
  const primaryInsurance =
    data?.patient?.insurance?.length > 0
      ? data.patient.insurance.find((insur) => insur.priority === 1)
      : undefined;
  const secondaryInsurance =
    data?.patient?.insurance?.length > 0
      ? data.patient.insurance.find((insur) => insur.priority === 2)
      : undefined;
  return {
    ...data?.patient,
    id: data?.patient?.id ?? "",
    firstName: data?.patient?.firstName ?? "",
    lastName: data?.patient?.lastName ?? "",
    middleName: data?.patient?.middleName ?? "",
    mrn: data?.patient?.mrn ?? "",
    dob: data?.patient?.dob ?? "",
    homePhoneNumber: data?.patient?.homePhoneNumber ?? "",
    workPhoneNumber: data?.patient?.workPhoneNumber ?? "",
    cellPhoneNumber: data?.patient?.cellPhoneNumber ?? "",
    gender: data?.patient?.gender ?? "",
    address: data?.patient?.address ?? "",
    latitude: data?.patient?.latitude ?? "",
    longitude: data?.patient?.longitude ?? "",
    city: data?.patient?.city ?? "",
    state: data?.patient?.state ?? "",
    zip: data?.patient?.zip ?? "",
    insurance: [
      {
        groupNumber: primaryInsurance?.groupNumber ?? "",
        insuranceCompanyId: primaryInsurance?.insuranceCompanyId ?? "",
        insuranceCompanyName: primaryInsurance?.insuranceCompanyName ?? "",
        policyNumber: primaryInsurance?.policyNumber ?? "",
        priority: 1,
      },
      {
        groupNumber: secondaryInsurance?.groupNumber ?? "",
        insuranceCompanyId: secondaryInsurance?.insuranceCompanyId ?? "",
        insuranceCompanyName: secondaryInsurance?.insuranceCompanyName ?? "",
        policyNumber: secondaryInsurance?.policyNumber ?? "",
        priority: 2,
      },
    ],
  };
};

export const extractFirstNameLastNameFromText = (fullName) => {
  const [firstName, ...lastName] = fullName.trim().split(/\s+/);
  return { firstName, lastName: lastName.join(" ") };
};
