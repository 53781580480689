import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Drawer, CssBaseline, Grid } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import Routes from "../Routes";
import SidebarMenu from "../SidebarMenu";
import AuthenticatedMenu from "../AuthenticatedMenu";
import UnauthenticatedMenu from "../UnauthenticatedMenu";
import Notifier from "../Notifier";
import clsx from "clsx";
import { login, storeCodeAndGetSubDomain } from "./auth";
import queryString from "query-string";
import BrandLoader from "../BrandLoader";
import topLogo from "./ph-logo-exchange.svg";
import topLogoAlt from "./ph-logo-capture.svg";
import logoWhite from "./White_Proficient_Health_Logo.png";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import AppError from "./AppError";
import { useDispatch } from "react-redux";
import UserSubs from "../UserSubs";
import { useAppSetting } from "../../context";
import { MainUploadButtonContainer } from "../MainUploadButton/MainUploadButtonContainer";
import PropTypes from "prop-types";

const drawerWidth = "200px";

export const styles = (theme) => ({
  root: {
    display: "flex",
  },
  title: {
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  titleImage: {
    width: "180px",
    margin: "6px",
  },
  drawer: {
    width: drawerWidth,
    minWidth: 200,
    flexShrink: 0,
    color: grey[200],
    backgroundColor: theme.palette.primary.main,
    backgroundSize: "contain",
    backgroundPosition: "left bottom",
    backgroundRepeat: "no-repeat",
    borderRight: "none",
    overflowX: "hidden",
  },
  drawerPaper: {
    width: drawerWidth,
    minWidth: 184,
    background: "inherit",
    color: "inherit",
    borderRight: "none",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    width: "calc(100% - 200px)",
    padding: theme.spacing(3),
    transition: "margin .25s",
  },
  containerGrid: {
    height: "100%",
    position: "relative",
  },
  extraDrawer: {
    marginLeft: 120,
  },
  navWrapper: {
    display: "block",
    height: "100%",
    width: "100%",
  },
});

const stripBaseUrl = (url) => {
  // Do not strip if localhost
  if (process.env.REACT_APP_BASENAME === "/") {
    return url;
  }

  if (_.startsWith(url, process.env.REACT_APP_BASENAME)) {
    return _.replace(url, process.env.REACT_APP_BASENAME, "");
  }

  return url;
};

async function trySignIn(
  signIn,
  signInFailure,
  optimizely,
  history,
  code,
  loginDomain,
  state
) {
  try {
    const { token, redirect } = await login(code, loginDomain, state);
    signIn(token, optimizely);
    if (redirect) {
      history.push(stripBaseUrl(redirect));
    }
  } catch (e) {
    signInFailure(
      `You do not have any permission to access ${window.location.hostname}. Please contact your administrator with any questions`
    );
  }
}

let sessionTimer;

export const Container = ({
  signIn,
  signOut,
  determineIfSignedIn,
  signInFailure,
  checkAppVersion,
  initApiHost,
  enqueueSnackbar,
  location,
  history,
  optimizely,
  classes,
  authStateDetermined,
  hasExtraDrawer = false,
  appError,
  errorType,
  userId,
  clientId,
  environment,
}) => {
  const dispatch = useDispatch();

  // Check if the current path is /documents
  const isDocumentsRoute = location?.pathname === "/documents";

  useEffect(() => {
    dispatch(checkAppVersion());
    dispatch(initApiHost());
    const queryParams = queryString.parse(location.search);
    if (location.hash) {
      if (location.hash.includes("access_token")) {
        const hashParams = location.hash
          .replace("#", "")
          .split("&")
          .map((v) => v.split("="))
          .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});
        signIn(hashParams.access_token, optimizely);
        window.history.replaceState(null, null, " ");
      }
    } else if (queryParams.state) {
      if (queryParams.code) {
        //go back to original domain to get the proper token verifier
        if (queryParams.state.includes(",")) {
          const domain = queryParams.state.split(",")[1];
          domain === "localhost"
            ? window.location.replace(
                `http://localhost:3000/exchange/?code=${
                  queryParams.code
                }&state=${queryParams.state.split(",")[0]}`
              )
            : window.location.replace(
                `https://${domain}/exchange/?code=${queryParams.code}&state=${
                  queryParams.state.split(",")[0]
                }`
              );
        } else {
          window.location.replace(
            `${storeCodeAndGetSubDomain(
              queryParams.code,
              queryParams.state
            )}/exchange/?state=${queryParams.state}`
          );
        }
      } else {
        //note that we only want to redirect to other subdomains of our domain
        trySignIn(
          signIn,
          signInFailure,
          optimizely,
          history,
          window.location.host,
          queryParams.state
        );
      }

      window.history.replaceState(null, null, " ");
    } else {
      dispatch(determineIfSignedIn(optimizely));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const token = sessionStorage.getItem("hermesUIBearerToken");
  const authenticated = useSelector((state) => state.auth.authenticated);
  const userInfo = useSelector((state) => state.auth);
  const { captureMode, primaryNetworkId, loading } = useAppSetting();

  useEffect(() => {
    if (environment && userInfo?.userId && loading === false) {
      window.pendo.initialize({
        visitor: {
          id: `${environment}_${userInfo.userId}`, // Required if user is logged in
          email: userInfo?.username, // Recommended if using Pendo Feedback, or NPS Email
          full_name: `${userInfo?.firstName} ${userInfo?.lastName}`, // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
          width: window.innerWidth,
          height: window.innerHeight,
          primaryNetworkId: primaryNetworkId?.join(", ") || null,
        },

        account: {
          id: `${environment}_${userInfo.clientId}`, // Highly recommended
          name: userInfo.orgName, // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
      console.log("Pendo is initialized");
    }
  }, [userInfo?.userId, primaryNetworkId, environment, loading]); // eslint-disable-line

  if (token) {
    clearTimeout(sessionTimer);
    const { exp } = jwtDecode(token);
    const expirationTime = exp * 1000 - 60000; // Subtract a minute to account for any latency
    let delay = expirationTime - Date.now();
    if (delay < 0) {
      delay = 0;
    }
    sessionTimer = setTimeout(() => {
      const root = document.getElementById("root");
      root.style.pointerEvents = "none";
      root.style.opacity = "0.8";
      enqueueSnackbar({
        message: "Your session has timed out. Please log in to continue.",
        options: {
          variant: "error",
        },
      });
      setTimeout(() => {
        signOut(null, window.location.origin);
        root.style.pointerEvents = "auto";
        root.style.opacity = "1";
      }, 2500);
    }, delay);
  }

  return authStateDetermined ? (
    <div className={classes.root}>
      {clientId && userId ? <UserSubs {...{ clientId, userId }} /> : null}
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid
          className={authenticated ? classes.navWrapper : classes.containerGrid}
          container
          direction="column"
          id="main_navbar"
        >
          {userId ? (
            !captureMode ? (
              <div className={classes.title}>
                <img
                  src={topLogo}
                  alt="PH Exchange Logo"
                  className={classes.titleImage}
                />
              </div>
            ) : (
              <div className={classes.title}>
                <img
                  src={topLogoAlt}
                  alt={"PH Capture Logo"}
                  className={classes.titleImage}
                />
              </div>
            )
          ) : (
            <div className={classes.title}>
              <img
                src={logoWhite}
                alt="PH Logo"
                className={classes.titleImage}
              />
            </div>
          )}
          {isDocumentsRoute && <MainUploadButtonContainer />}
          <SidebarMenu />
          <AuthenticatedMenu />
          <UnauthenticatedMenu />
        </Grid>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.extraDrawer]: Boolean(hasExtraDrawer),
        })}
      >
        {appError ? (
          <AppError type={errorType} />
        ) : (
          <>
            <Routes />
            <Notifier />
          </>
        )}
      </main>
    </div>
  ) : (
    <BrandLoader />
  );
};

// PropTypes
Container.propTypes = {
  signIn: PropTypes.func,
  signOut: PropTypes.func,
  determineIfSignedIn: PropTypes.func,
  signInFailure: PropTypes.func,
  checkAppVersion: PropTypes.func,
  initApiHost: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }),
  history: PropTypes.object,
  optimizely: PropTypes.object,
  classes: PropTypes.object,
  authStateDetermined: PropTypes.bool,
  hasExtraDrawer: PropTypes.bool,
  appError: PropTypes.bool,
  errorType: PropTypes.string,
  userId: PropTypes.string,
  clientId: PropTypes.string,
  environment: PropTypes.string,
};
